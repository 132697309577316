import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getSrc, getImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import Seo from "../../components/seo";
import BlogLayout from "../../components/bloglayout";
import PreviousNextLinks from "../../components/previousnextlinks";

import * as styles from "./scss/blogpost.module.scss";

const BlogPost = ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const { html, frontmatter } = post;
  const {
    title,
    date,
    description,
    image,
    ogImage,
    imageAlt,
    imageQuote,
    imageName,
    imageFlickrUrl,
    imageInstagramUrl,
  } = frontmatter;

  const { previous, next } = pageContext;

  const previousSlug = previous?.fields?.slug;
  const previousTitle = previous?.frontmatter?.title;

  const nextSlug = next?.fields?.slug;
  const nextTitle = next?.frontmatter?.title;

  const postImage = getImage(image);
  const ogImageSrc = getSrc(ogImage);

  return (
    <BlogLayout isPost>
      <Seo
        title={title}
        description={description}
        ogType="article"
        ogImage={ogImageSrc}
      />
      <article className={styles.blogPostTemplate}>
        <header className={styles.postHeader}>
          <div className={styles.postSideContainer}>
            <div className={styles.postImageContainer}>
              <GatsbyImage image={postImage} alt={imageAlt} />
              <div className={styles.postImageInfo}>
                <p>{imageName}</p>
                <div className={styles.postImageLinks}>
                  <OutboundLink
                    href={imageInstagramUrl}
                    target="_blank"
                    rel="external noopener noreferrer"
                    title="View on Instagram"
                  >
                    Instagram
                  </OutboundLink>
                  <OutboundLink
                    href={imageFlickrUrl}
                    target="_blank"
                    rel="rel noopener noreferrer"
                    title="View on Flickr"
                  >
                    Flickr
                  </OutboundLink>
                </div>
              </div>
            </div>
            <p className={styles.postSideQuote}>{imageQuote}</p>
          </div>
          <h1 className={styles.postTitle}>{title}</h1>
          <p className={styles.postDate}>{date}</p>
        </header>
        <section
          className={styles.postBody}
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <section className={styles.readAlsoSection}>
          <h5>Read also:</h5>
          <PreviousNextLinks
            previousHref={previousSlug}
            previousText={previousTitle}
            nextHref={nextSlug}
            nextText={nextTitle}
          />
        </section>
      </article>
    </BlogLayout>
  );
};

export const postQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        imageQuote
        imageAlt
        imageName
        imageFlickrUrl
        imageInstagramUrl
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        ogImage: image {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              height: 630
              layout: FIXED
              formats: [JPG]
            )
          }
        }
      }
    }
  }
`;

export default BlogPost;
