// extracted by mini-css-extract-plugin
export var blogPostTemplate = "blogpost-module--blog-post-template--sQIPR";
export var postBody = "blogpost-module--post-body--79iUU";
export var postDate = "blogpost-module--post-date--zTHb8";
export var postHeader = "blogpost-module--post-header--v2CN4";
export var postImageContainer = "blogpost-module--post-image-container--t115a";
export var postImageInfo = "blogpost-module--post-image-info--rxTPj";
export var postImageLinks = "blogpost-module--post-image-links--JPtRY";
export var postSideContainer = "blogpost-module--post-side-container--92zHM";
export var postSideQuote = "blogpost-module--post-side-quote--ONzED";
export var postTitle = "blogpost-module--post-title--2rVIK";
export var readAlsoSection = "blogpost-module--readAlsoSection--p1DkA";