import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import * as classes from "./scss/style.module.scss";

const PreviousNextLinks = ({
  previousHref,
  previousText,
  nextHref,
  nextText,
}) => (
  <ul className={classes.container}>
    {previousHref && previousText && (
      <li>
        <Link to={previousHref} className={classes.link}>
          {previousText}
        </Link>
      </li>
    )}
    {nextHref && nextText && (
      <li>
        <Link to={nextHref} className={classes.link}>
          {nextText}
        </Link>
      </li>
    )}
  </ul>
);

PreviousNextLinks.propTypes = {
  previousHref: PropTypes.string,
  previousText: PropTypes.string,
  nextHref: PropTypes.string,
  nextText: PropTypes.string,
};

export default PreviousNextLinks;
